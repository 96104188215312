import { createRoot } from 'react-dom/client'

import CookieConsent from './cookie-consent'

const container = document.getElementById('analytics')
    , root = container && createRoot(container)

root?.render(
    <CookieConsent />
)
