import * as React from 'react'
import startGoogleAnalytics from '_/analytics/google'

const cookieDisabled = !window.navigator.cookieEnabled

function CookieConsent() {
    const [rejected, reject] = useReject()
        , [accepted, accept] = useAccept()

        , acceptButtonRef = React.createRef<HTMLButtonElement>()
        , rejectButtonRef = React.createRef<HTMLButtonElement>()
        , isDone = rejected || accepted || cookieDisabled

    useRevokeEffect(accepted)
    useAcceptEffect(accepted)
    useFocusEffect(acceptButtonRef, isDone)
    useKeyPress(reject, acceptButtonRef, rejectButtonRef, isDone)

    if (isDone)
        return null

    const blurHandler = (e: React.FocusEvent<HTMLButtonElement>) => {
        if (acceptButtonRef.current && e.relatedTarget !== acceptButtonRef.current && e.relatedTarget !== rejectButtonRef.current)
            acceptButtonRef.current.focus()
    }

    return (
        <div>
            <div className='cookie-consent-background' />

            <div className='cookie-consent'>
                <div className='cookie-consent__title'>
                    Cookie consent
                </div>

                <div className='cookie-consent__text'>
                    We'd like to track your visit. To do this, we need to add cookies to your browser.
                    These will let us know what pages you're reading and allow us to plan better,
                    more useful content in the future.
                    If you are happy for us to do this, click “Agree and proceed”.
                    If do not wish for us to set cookies, you may dismiss this message
                </div>

                <div>
                    <button
                        type='button'
                        className='btn btn-primary'
                        ref={acceptButtonRef}
                        onClick={accept}
                        onBlur={blurHandler}
                        data-testid='cookie-agree'
                    >
                        Agree and proceed
                    </button>

                    <button
                        type='button'
                        className='cookie-consent__reject'
                        ref={rejectButtonRef}
                        aria-label='Close'
                        onClick={reject}
                        onBlur={blurHandler}
                    >
                        <span aria-hidden='true'>&times;</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

function useReject() {
    const [rejected, setRejected] = React.useState(false)
    return [rejected, () => setRejected(true)] as const
}

function useAccept() {
    const [accepted, setAccepted] = React.useState(!cookieDisabled && hasAcceptedCookie())
    return [accepted, () => setAccepted(true)] as const
}

function useRevokeEffect(accepted: boolean) {
    React.useEffect(
        () => {
            if (cookieDisabled)
                return

            if (!accepted) {
                clearCookies()
            }
        },
        [accepted] // one time effect
    )
}

function useAcceptEffect(accepted: boolean) {
    React.useEffect(
        () => {
            if (cookieDisabled)
                return

            if (!accepted)
                return

            startGoogleAnalytics()

            if (!hasAcceptedCookie())
                setAcceptCookie()
        },
        [accepted]
    )
}

function useFocusEffect(buttonRef: React.RefObject<HTMLButtonElement>, isDone: boolean) {
    React.useLayoutEffect(
        () => {
            if (isDone)
                return

            if (buttonRef.current) {
                buttonRef.current.focus()
            }
        },
        [isDone, buttonRef]
    )
}

function useKeyPress(
    onReject: () => void,
    acceptButtonRef: React.RefObject<HTMLButtonElement>,
    rejectButtonRef: React.RefObject<HTMLButtonElement>,
    isDone: boolean
) {
    const keyDownHandler = (e: KeyboardEvent) => {
        switch (e.keyCode) {
            case 9:
            {
                if (acceptButtonRef.current === document.activeElement)
                    rejectButtonRef.current?.focus()
                else
                    acceptButtonRef.current?.focus()

                if (rejectButtonRef.current && acceptButtonRef.current)
                    e.preventDefault()
            }
            break

            case 27:
            {
                clearCookies()
                onReject()
                clearEventHandlers()
            }
            break
        }
    }

    const clearEventHandlers = () => {
        document.removeEventListener('keydown', keyDownHandler)
    }

    const addEventHandlers = () => {
        document.addEventListener('keydown', keyDownHandler)
    }

    React.useEffect(
        () => {
            if (isDone)
                return

            if (acceptButtonRef.current && rejectButtonRef.current)
                addEventHandlers()

            return () => clearEventHandlers()
        },
        // eslint-disable-next-line
        [isDone]
    )
}

const COOKIES_ACCEPTED = 'cookies_accepted'

function hasAcceptedCookie() {
    return allCookieNames().indexOf(COOKIES_ACCEPTED) >= 0
}

function setAcceptCookie() {
    const nextMonth = new Date()
    nextMonth.setMonth(nextMonth.getMonth() + 1)

    const cookie = cookieWithExpiration(COOKIES_ACCEPTED, nextMonth.getTime())
    window.document.cookie = cookie
}

function clearCookies() {
    const cookies = allCookieNames().map(_ => cookieWithExpiration(_, 0))
    cookies.forEach(_ => window.document.cookie = _)
}

function cookieWithExpiration(name: string, date: number) {
    const dateString = new Date(date).toUTCString()
    return `${name}=; expires=${dateString}; path=/`
}

function allCookieNames() {
    return window.document.cookie.split(';').map(_ => _.split('=')[0].trim())
}

export default CookieConsent
