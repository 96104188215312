import CONFIG from '_/constants/config'
let trackingCode: string | undefined

function start() {
    const base = 'https://www.googletagmanager.com/gtag/js?id='
        , xhr = new XMLHttpRequest()

    xhr.open('GET', CONFIG.api + '/google-analytics/tracking-code')
    xhr.responseType = 'text'
    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            const code = xhr.responseText.replace(new RegExp('"', 'g'), '')
            trackingCode = code
            configure(code)
            updateDOM(base + code)
        }
    }
    xhr.send()
}

function getGtag() {
    (window as any).dataLayer = (window as any).dataLayer || []

    return function gtag(..._args: unknown[]) {
        // Must push IArguments object, not an array.
        (window as any).dataLayer.push(arguments)
    }
}

function firePageViewEvent(pageName: string, pagePath: string) {
    const gtag = getGtag()

    gtag('config', trackingCode, {
        page_title : pageName,
        page_path: pagePath,
    })
}

function configure(trackingCode: string) {
    const gtag = getGtag()

    gtag('js', new Date())
    gtag('config', trackingCode)
}

function updateDOM(url: string) {
    const d = document
        , g = d.createElement('script')
        , h = d.getElementsByTagName('head')[0]

    g.type = 'text/javascript'
    g.async = true
    g.src = url
    h.appendChild(g)
}

export default start
export { firePageViewEvent }
